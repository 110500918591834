import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`25th July 2023`}</p>


    <h2 {...{
      "id": "-security-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-security-fixes",
        "aria-label": " security fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🔒 Security fixes`}</h2>
    <ul>
      <li parentName="ul">{`Armeria server now strips `}<a parentName="li" {...{
          "href": "https://docs.spring.io/spring-framework/reference/web/webmvc/mvc-controller/ann-methods/matrix-variables.html"
        }}>{`matrix variables`}</a>{`
from a request path before finding a matching route. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/security/advisories/GHSA-wvp2-9ppw-337j"
        }}>{`CVE-2023-38493`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Previously, when an Armeria decorator is used to decorate a Spring controller served
using `}<a parentName="li" {...{
              "href": "type://TomcatService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/tomcat/TomcatService.html"
            }}>{`type://TomcatService`}</a>{` or `}<a parentName="li" {...{
              "href": "type://JettyService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/jetty/JettyService.html"
            }}>{`type://JettyService`}</a>{`, the request bypassed the
decorator if the request path contains matrix variables.`}</li>
          <li parentName="ul">{`You can use a regex for the decorator as a workaround before you upgrade Armeria.`}
            <ul parentName="li">
              <li parentName="ul">{`For example, `}<inlineCode parentName="li">{`sb.decoratorUnder("regex:^/prefix.*", decorator)`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`sb.decoratorUnder("/prefix", decorator)`}</inlineCode>{`.`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Fixed a regression where the logger names of `}<a parentName="li" {...{
          "href": "type://LoggingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/LoggingService.html"
        }}>{`type://LoggingService`}</a>{` and `}<a parentName="li" {...{
          "href": "type://LoggingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/logging/LoggingClient.html"
        }}>{`type://LoggingClient`}</a>{` have
changed since `}<a parentName="li" {...{
          "href": "/release-notes/1.24.0"
        }}>{`1.24.0`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5056"
        }}>{`#5056`}</a></li>
      <li parentName="ul">{`You can now specify a Unix domain socket path in abstract namespace. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5014"
        }}>{`#5014`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5036"
        }}>{`#5036`}</a></li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['ikhoon', 'jrhee17', 'lishiki', 'minwoox', 'nao0811ta', 'ta7uw', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      